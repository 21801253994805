import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer
        className="bg-[#ef233c] shadow m-2 text-white md:rounded-tl-[25px] md:rounded-br-[25px] md:rounded-tr-none md:rounded-bl-none rounded-lg"
        style={{
          background:
            "linear-gradient(135deg, #68c7f3 0%,#2bb8fc 50%, #039be5 100%)",
        }}
      >
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-around">
          <span className="text-sm text-center text-white flex justify-center">
            © {currentYear}
            <Link to="/" className="hover:underline mx-2 text-[#FFF]">
              rocogged.xyz
            </Link>
            . All Rights Reserved.
          </span>
          <ul className="flex flex-wrap flex-col sm:flex-row items-center mt-3 text-sm font-medium text-white sm:mt-0">
            <li className="my-2 sm:my-0">
              <Link to="/about" className="hover:underline md:me-6">
                About
              </Link>
            </li>
            <li className="my-2 sm:my-0">
              <Link to="/privacy-policy" className="hover:underline md:me-6">
                Privacy Policy
              </Link>
            </li>
            <li className="my-2 sm:my-0">
              <Link to="/term-condition" className="hover:underline md:me-6">
                Term Condition
              </Link>
            </li>
            <li className="my-2 sm:my-0">
              <Link to="/contact-us" className="hover:underline">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}
