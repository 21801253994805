import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 shadow-2xl text-black rounded-lg m-3 text-lg">
        <h1 className="text-3xl font-bold text-center">About Us</h1>

        <h2 className="text-2xl font-semibold mt-6">Welcome to Rocogged!</h2>
        <p className="my-3">
          At <b>Rocogged</b>, we are dedicated to offering innovative solutions
          and resources to help individuals and businesses thrive in today’s
          fast-paced world. Our mission is to provide the tools, insights, and
          support needed for success in various aspects of professional and
          personal growth.
        </p>

        <h2 className="text-2xl font-semibold mt-6">Our Story</h2>
        <p className="my-3">
          Founded in <b>2024</b>, <b>Rocogged</b> was established with the
          goal of bridging gaps and fostering growth through creative and
          practical solutions. We are committed to delivering high-quality
          services and resources that empower our clients to achieve their
          goals.
        </p>

        <h2 className="text-2xl font-semibold mt-6">Our Team</h2>
        <p className="my-3">
          Our team comprises skilled professionals with extensive experience in
          various fields. We work together to offer expert guidance and support
          tailored to the unique needs of our clients. Our collective expertise
          ensures that we provide valuable insights and solutions.
        </p>

        <h2 className="text-2xl font-semibold mt-6">Our Services</h2>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Consulting and Solutions:</b> Expert consulting services to help
            you navigate challenges and implement effective solutions.
          </li>
          <li className="my-2">
            <b>Training and Workshops:</b> A variety of training programs and
            workshops designed to enhance skills and drive growth.
          </li>
          <li className="my-2">
            <b>Resources and Tools:</b> Access to a range of resources and tools
            to support your professional and personal development.
          </li>
        </ul>

        <p className="my-3">
          Thank you for choosing <b>Rocogged</b>. We look forward to helping you
          achieve your objectives and drive success.
        </p>
      </section>
    </>
  );
}
