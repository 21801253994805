import React, { useState } from "react";
import Loader from "./Loader";

export default function ContactUs() {
  const [isLoad, setIsLoad] = useState(true);
  const [formData, setFormData] = useState({
    subject: "",
    email: "",
    note: "",
  });

  const resetForm = () => {
    setFormData({
      subject: "",
      email: "",
      note: "",
    });
  };

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("http://localhost:3040/api/contact-us", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, site_name: "rocogged.xyz" }),
      });
      if (response.ok) {
        alert("Your message has been sent successfully!");
        resetForm();
      } else {
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <h1 className="text-center text-2xl text-[#039be5]">Contact Us</h1>

      <div className="mx-auto my-5 max-w-sm p-4  border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="subject"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your subject
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Enter Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="name@company.com"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label
              htmlFor="note"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your note
            </label>
            <textarea
              id="note"
              name="note"
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Write your thoughts here..."
              value={formData.note}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            style={{
              background:
                "linear-gradient(135deg, #68c7f3 0%,#2bb8fc 50%, #039be5 100%)",
            }}
          >
            Send
          </button>
        </form>
      </div>
    </>
  );
}
