import React, { useState } from "react";
import Loader from "./Loader";

export default function PrivacyPolicy() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <section className="p-6 shadow-2xl text-black rounded-lg m-3 text-lg">
      <h1 className="text-3xl font-bold text-center mb-4">Privacy Policy</h1>

      <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
      <p className="mb-4">
        At Rocogged, we are committed to protecting your privacy. This Privacy
        Policy explains how we collect, use, and safeguard your personal
        information.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Information We Collect</h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          <b>Personal Information:</b> We collect personal information such as
          your name, email address, and contact details when you register on our
          website or use our services.
        </li>
        <li className="mb-2">
          <b>Usage Data:</b> We collect information about how you use our
          website, including your IP address, browser type, and access times.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">
        How We Use Your Information
      </h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          <b>To Provide Services:</b> We use your information to deliver and
          improve our consulting, training, and resource services.
        </li>
        <li className="mb-2">
          <b>To Improve Our Services:</b> We analyze usage data to enhance our
          website and service offerings.
        </li>
        <li className="mb-2">
          <b>To Communicate:</b> We use your contact information to send
          important updates and respond to your inquiries.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Information Sharing</h2>
      <p className="mb-4">
        We do not sell, trade, or otherwise transfer your personal information
        to outside parties, except as required by law or to trusted third
        parties who assist us in operating our website and services.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Data Security</h2>
      <p className="mb-4">
        We implement a variety of security measures to protect your personal
        information. However, no method of transmission over the internet or
        electronic storage is 100% secure.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Changes to This Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. Changes will be
        posted on this page, and the effective date will be updated.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at{" "}
        <a
          href="mailto:privacy@rocogged.xyz"
          className="text-blue-600 hover:underline"
        >
          privacy@rocogged.xyz
        </a>
        .
      </p>
    </section>
  );
}
